<div class="file-upload-container" fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="!simpleView">
    
    <div class="text" fxFlex>
        <div class="label" *ngIf="!fileName && !uploadedFile && !invalidFileExtension && !invalidFileSize">{{uploadText}}</div>
    
        <div class="file-info" *ngIf="fileName && !downloadEnabled">{{fileName}}</div>
        
        <a class="file-info" href="#" (click) ="$event.preventDefault(); handleFileDownloadClicked()" [download]="fileName" *ngIf="fileName && downloadEnabled">{{fileName}}</a>

        <div *ngIf="invalidFileExtension" class="error">Invalid file type</div>
        
        <div *ngIf="invalidFileSize && !invalidFileExtension" class="error">Invalid file size, must be smaller than {{maximumFileSizeMb}}MB</div>
    </div>

    <button mat-raised-button [disabled]="working || disableUpload" class="ghost small" (click)="addFile()">
        <div fxLayout="row" fxLayoutGap="10px" [fxLayoutAlign]="layoutAlign">
            <mat-icon>cloud_upload</mat-icon>
            <div *ngIf="!working">{{uploadButtonLabel}}&nbsp;</div>
            <div *ngIf="working">Please wait.&nbsp;</div>
        </div>
    </button>
</div>

<button *ngIf="simpleView" mat-raised-button class="ghost small simple-button" [disabled]="working || disableUpload" (click)="addFile()">

    <div fxLayout="row" fxLayoutGap="10px" [fxLayoutAlign]="layoutAlign">
        <mat-icon>cloud_upload</mat-icon>
        <div *ngIf="!working">{{uploadButtonLabel}}&nbsp;</div>
        <div *ngIf="working">Please wait.&nbsp;</div>
    </div>
    
</button>

<input *ngIf="!useCamera" type="file" #upload [hidden]="true" (change)="onFileAdded()" [accept]="fileTypes">
<input *ngIf="useCamera" type="file" #upload [hidden]="true" (change)="onFileAdded()" [accept]="imageFileTypes" capture>